@import "../theme/variables.css";
@import "var";
@import "fonts";
@import "gmaps";

:root {
  --statusbar_mb_padding: 32px;
  --background: $dark_blue;
  --ion-toolbar-color: white;
  --ion-color-step-650: black;
  --ion-color-secondary: #1a4273;
}

@mixin box {
  padding: 20px 15px;
}
ion-button{
  text-transform: inherit;
}
/* fix status bar overlay b3t */
//html {
//  padding-top: var(--statusbar_mb_padding) !important;
//}

//ion-tabs {
//  height: calc(100% - var(--statusbar_mb_padding)) !important;
//}

ion-header.ios > ion-toolbar {
  height: 76px; /* 32px + 42px */
  padding-top: 32px;
}

/* fix status bar overlay END */
:root {
  --ion-text-borcolor: black;
  --ion-font-family: 'Arial';
  --ion-item-color: black;
  --webkit-control-background: white;
  --ion-safe-area-bottom: 10px;
  //--ion-color-primary: $dark_blue;
}

html.plt-android {
  margin-top: var(--mb_statusbar_offset);

  div.tabs-inner {
    //height: calc(100vh - var(--mb_statusbar_offset) - 35px);
    //--height: calc(100vh - var(--mb_statusbar_offset) - 35px);
  }
}

ion-action-sheet.ac_sh_big_button .action-sheet-button.sc-ion-action-sheet-ios {
  height: 84px;
  line-height: 24px;

  &.action-sheet-cancel {
    height: 50px;
    line-height: inherit;
  }
}

ion-action-sheet.ios.select-action-sheet.ac_sh_big_button {
  height: calc(100vh - 56px);
}

div.action-sheet-group.action-sheet-group-cancel.sc-ion-action-sheet-ios {

}

ion-label {
  color: black;
}

ion-tab-bar ion-label {
  color: white
}

ion-tab-bar ion-tab-button {
  //margin-top: -5px;
}

.ionicon {
  width: 20px;
}

a {
  text-decoration-line: none;
}

.dib {
  display: inline-block
}

.p03 {
  padding: 0.3rem;
}

.w-48 {
  width: 48%
}

.w-50 {
  width: 50%
}

.w-50-cen {
  width: 50%;
  margin-left: 25%
}

.w-60 {
  width: 60%
}

.w-60-cen {
  width: 60%;
  margin-left: 20%
}

.w99, .w-99 {
  width: 99%;
}

.w100, .w-100 {
  width: 100%;
}

.w48 {
  width: 48% !important;
}

.w33 {
  width: 33% !important;
}

.w-75-cen {
  width: 75%;
  margin-left: 12.5%;
}

.w-90-cen {
  width: 90%;
  margin-left: 5%;
}

.w10 {
  width: 10%
}

.w15 {
  width: 15%
}

.w20 {
  width: 20%
}

.w25 {
  width: 25%
}

.w30 {
  width: 30%
}

.w35 {
  width: 35%
}

.w40 {
  width: 40%
}

.w60 {
  width: 60%
}

.w70 {
  width: 70%
}

.row {
  float: left;
  width: 100%;
}

.float_left {
  float: left;
}

.center_50 {
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
}

.mb5 {
  margin-bottom: 5px;
}

.mt5 {
  margin-top: 5px;
}

.pull-right, .float_right {
  float: right;
}

.p10 {
  padding: 10px;
}

.mt10 {
  margin-top: 10px;
}

/*util //*/
#logo {
  width: 100%;
  padding-top: 20px;
}

.inline_block {
  display: inline-block
}

.th {
  line-height: 20px;
  font-weight: bold;
  font-size: 90%;
  padding-bottom: 5px;
  border-bottom: 1px solid $grey_border;
  margin-bottom: 15px;
}

.tr, .th {
  float: left;
  width: 100%;
}

.tr > .td, .th > .td {
  float: left;
}

input.native-input, input.native-input.sc-ion-input-ios, input.native-input.sc-ion-input-md {
  border-bottom: 1px solid $grey_border;
  --padding-top: 0;
}

body.offset_input {
  position: relative;
  top: -23vh;
}

.table.p05 {
  * .td {
    padding: 0.5rem;
  }
}

.table.p03 {
  * .td {
    padding: 0.3rem;
  }
}

#hero_img {
  width: 100%;
  display: block;
}

/*
div.tabs-inner {
  background-color: #efefef;
}
*/

#content {
  width: 100%;
  height: calc(100% - 300px);
  background-color: #e3e3e3;

  &.has_pad {
    padding: 8px;
  }
}

.hidden {
  display: none !important;
}

.d-flex {
  display: flex !important;
}

.d-flex-cen {
  display: flex !important;
  justify-content: center;
}

#login_box {
  @include box;
  padding-top: 1px;
  background-color: white;
}

#forgot_box {
  @include box;
}

/*
ion-item.no_border div.item-inner{
  border: none;
}*/
div.item-inner {
  border: none;
}

.small_text {
  font-size: 75% !important;
}

ion-item {
  --border-style: none;
}

ion-select, ion-select * {
  color: black;
}

.color_pri {
  color: var(--ion-color-primary)
}

.full_width_title {
  width: 100vw;
  float: left;
  background-color: $grey_bg;
  text-transform: uppercase;
  padding: 8px;
  margin-left: -10px;
  margin-top: 15px;
  margin-bottom: 15px;
}

[role="banner"] {
  background-color: #001c42;
}

h2 {
  font-weight: bold;
  color: var(--ion-color-primary)
}

h3 {
  color: var(--ion-color-primary)
}

ion-button {
  font-weight: bold;
  --border-radius: 6px;
  height: 40px;
  --padding-start: 1.7em;
  --padding-end: 1.7em;

  &[size^="tiny"] {
    --padding-start: 0.2em;
    --padding-end: 0.2em;

    &.two_lines {
      width: 22%;
      white-space: normal;
      flex-grow: 0;
      --border-radius: 0;
      margin-inline: 4px;
    }
  }
    &[size^="nano"] {
      height: 22px;
      width: 60px;
      --padding-start: 0.2em;
      --padding-end: 0.2em;
    }
}

ion-button.button-small {
  --padding-start: 0.9em;
  --padding-end: 0.9em;
}

body {
  font-family: Arial, serif;
}

ion-title {
  font-size: 12pt;
  font-weight: bold;
  color: white;
  line-height: 34px;
  vertical-align: initial;
}

ion-toolbar > ion-buttons {
  color: white;
}

ion-card.infobox > ion-item > ion-label {
  color: var(--ion-color-primary) !important;
  font-weight: bold;
  font-size: 140% !important;
}

ion-toolbar.hidden {
  height: 0 !important;
  padding: 0 !important;
}

button.icn {
  background-color: transparent;
  background-repeat: no-repeat;
  height: 24px;
  min-width: 16px;

  &.back {
    background-image: url("../../public/assets/icon/bck.svg");
    background-position-y: center;
  }
}

ion-item.tiny {
  --padding-start: 2px;
  font-size: 12px !important;

  & * {
    font-size: 12px !important;
  }
}

ion-header, ion-toolbar {
  --background: $dark_blue;
  /*margin-top:30px;/* zsdf debugging ios */
}

ion-header ion-title {
  //padding-left: 0;
}

ion-toolbar {
  text-align: center;
}

.sc-ion-alert-ios-h {
  --max-width: 360px;
}

.f_cen {
  display: flex;
  //align-items: center;
  justify-content: left;
}

.border_bot {
  border-bottom: 1px solid $grey_border;
}

ion-modal {
  //width: 90%;
  --max-width: 90%;

  &.full_width {
    --max-width: 100%;
  }

  height: auto;
  --height: auto;
  //margin: 110px 5%;

  & > ion-content {
    padding: 10px
  }

  &.full_width {
    width: 100%;
    margin: 0;
  }
}

ion-backdrop {
  display: none;
  --display: none;
}

div [role="dialog"] {
  width: 90%;
  top: 147px;
}

ion-modal.full_height {
  //height: calc(100vh - var(--statusbar_mb_padding)) !important;
  height: calc(100vh) !important;

  div.modal-wrapper {
    height: 100%;
  }

  #ion-react-wrapper {
    height: calc(100vh - var(--mb_statusbar_offset)) !important;
    //height: calc(100vh - var(--statusbar_mb_padding)) !important;
  }
}

.wrapping-list .item-content, .wrapping-list .item {
  overflow: initial;
  white-space: initial;
}

ion-select-popover .item ion-label.sc-ion-label-md-s, ion-select-popover .item ion-label.sc-ion-label-md-h, ion-select-popover .item ion-label.sc-ion-label-md-s, ion-select-popover .item ion-label.sc-ion-label-ios-s, ion-select-popover .item ion-label.sc-ion-label-ios-h {
  overflow: initial;
  white-space: initial;
}

.text_center {
  text-align: center
}

.text_cap {
  text-transform: capitalize;
}

.padding_sm {
  padding: 10px
}

td {
  word-break: break-word
}

/*fix bug sweetalert2 setting height=auto ; breaks mobile app*/
body.swal2-height-auto {
  height: inherit !important;
}

/** Left align ionic action sheet ios */
.sc-ion-action-sheet-ios-h {
  text-align: left;
}

.action-sheet-button-inner.sc-ion-action-sheet-ios {
  justify-content: flex-start;
  text-align: left;
}

/** Left align ionic action sheet ios */
button.swal2-styled.swal2-confirm {
  background-color: var(--ion-color-primary);
}

.darkblue_invi {
  color: $dark_blue;
}

.datetime-time {
  padding-left: 0;
}

ion-datetime.no_year .datetime-calendar {
  display: none;
  height: 0;
}

/*host(.datetime-calendar){
  display: none;
  height: 0;
}*/
.datetime-calendar {
  --display: none;
  --height: 0;
}

ion-datetime.no_year {
}

.datetime-time {
  padding-left: 0;
}

/*ion-datetime ::slotted([name="time-label"]), span[slot="time-label"]{
  display: none;
}*/
ion-datetime {
  min-height: 54px;
  background-color: transparent;
}

span[slot="time-label"] {
  font-weight: normal;
  color: black;
}

ion-datetime-s.noyear {
  max-width: 100%;
  min-height: 35px;
  background-color: transparent;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ion_dts_inline_label {
  height: 35px;
  line-height: 35px;
  vertical-align: top;
}

.text_sm {
}

a.phone_email, a[href^=tel] {
  text-decoration: underline
}

ion-alert.horizontal_btn {
  div.alert-button-group {
    flex-direction: initial;
  }
}

.fullscreen_alert {
  --width: 95%;
  --min-width: 95%;
  max-height: 90%;

  .alert-message.sc-ion-alert-md, .alert-message.sc-ion-alert-ios {
    max-height: inherit;
  }
}

textarea.alert-input.sc-ion-alert-md {
  border: 1px solid var(--ion-color-step-150, #d9d9d9)
}

#grh_textarea_id {
}

.antique_white {
  color: antiquewhite !important;
}

.yellow {
  color: yellow !important;
}

.purple {
  color: $purple !important;
}

.font_bold {
  font-weight: bold !important;
}

.drider_list {
  background-color: $grey_bg;

  & > ion-item {
    --inner-padding-bottom: 0;
    margin: 6px 6px 6px 6px;
    border-radius: 15px;
    border-color: black;
    border-style: solid;
    border-width: 0.1px;

    & > ion-label {
      margin-top: 6px;
      padding-left: 6px;
      margin-right: 0;
    }

    & > .drider_list_idx {
      text-align: center;
      padding-left: 2px;
      padding-right: 2px;

      & > span {
        border-style: solid;
        border-radius: 13px;
        //border-color: var(--ion-color-primary);
        border-width: 1px;
        font-family: 'Roboto Mono', sans-serif;
        line-height: 25px;
        width: 25px;
        display: inline-block;
        color: white;
        background-color: var(--ion-color-primary);
        font-weight: bold;
      }
    }
  }
  & > ion-item.vh {
    & > .drider_list_idx {
      img{
        line-height: 25px;
        width: 25px;
        display: inline-block;
      }
    }
  }
}

div.drider_list_wrapper {
  padding: 6px;
  background-color: $grey_bg;
}
ion-label.list_label {
  font-weight: bold;
  color: var(--ion-color-primary)
}
.vert_align_text_top {
  vertical-align: -webkit-baseline-middle;
  * { vertical-align: text-top;}
}
ion-button.side_by_side {
  --padding-start: 26px;
  --padding-end: 26px;
  width: 50%;
  font-size: 90% !important;
  &.left{
    border-radius: 6px 0 0 6px;
    --border-radius: 6px 0 0 6px;
  }
  &.middle{
    width: 34%;
    margin-left: -3px;
    border-radius: 0;
    --border-radius: 0;
  }
  &.right{
    border-radius: 0 6px 6px 0;
    --border-radius: 0 6px 6px 0;
    margin-left: -5px;
    margin-right: 0;
  }
}

ion-button.purple_btn{
  color: white;
  --color: white;
  background-color: $purple;
  --background-color: $purple;
  --background: $purple;
}

.gm-style-iw-chr{
  height: 19px;
  padding-top: 0;
}

ion-content.vh {
  .rider_name {
    font-size: 140% !important;
  }

  div.drider_list_wrapper {
    ion-item {

    }
  }
}

div#list_separator {
  height: 30px;
}
ion-label {
  margin-bottom: 6px;
}

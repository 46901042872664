//.gm-style-iw-ch{ padding-top: 0}

button.gm-ui-hover-effect[title="Close"]{
  position: fixed !important;
  right: -11px;
  top: -11px !important;
}

img.icon_small {
  width: 16px; height: 16px;
  vertical-align: top;
}
.gm-style-iw-chr{
  height: 19px;
  padding-top: 0;
}

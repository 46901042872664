ion-header[curpage="clog"]{
  background-color: transparent;
  & ion-toolbar {
    --background: none;
    background-color: #001c42;
  }

  & ion-content {
    --background: "none";
  }
}
.leg{width: 100%; display: flex;flex-wrap: wrap;}
.leg > .header {
  width: 100%;
}
.leg span{
  //display: inline-block;
}
input{background-color: white;}
select[name="noCommuteReason"]{
  font-size: 8pt; line-height: 25px; height: 26px;
}
.fc table.fc-scrollgrid{height: 400px;}
.fc-view-container {
  td.disabled {
    background-color: #e3e3e3;
    color: #666;
  }
}
.add_edit_btn{
  border-color: #eee;
}
.ios .fc .add_edit_btn{
  font-size: 80%;
  background-color: inherit;
}
.android .fc .add_edit_btn{
  width: 80%;
  padding: 0.2em 0;
  font-size: 90%;
}
.fc-day-top.fc-force_show{opacity: 1}
.fc-day-top{text-align: center}
.fc-ltr .fc-basic-view .fc-day-top .fc-day-number{float: none}
ion-modal div[data-role="header"]{
  background-color: #001c42;
  color: white;
  & *{
    color: white;
  }
}
.fc .fc-toolbar-title{
  font-size: 110%;
}
#fullcalendar_wrapper{
  height: 420px;
  min-height: 420px;
  padding: .3rem;
}
#calendar_wrapper{max-height: 100px;}
.fc-header-toolbar{z-index: 4;}
ion-modal > div > ion-toolbar {
  --background: none;
  background-color: #001c42;
  height: 55px
}
div.fc-view tbody[role="rowgroup"]{
  height: 360px;
  min-height: 360px;
}
div.fc-view{
  .fc-daygrid-day-top{
    flex-direction: column;
    text-align: center;
  }
  a.fc-daygrid-day-number{
    width: 100%;
    text-align: center;
  }
}
div.fc-view.borderless{
  table,th,tr,td{
    border: none;
  }
}
.cur_date_mdy{
  font-weight: bold;
  font-size: 140%;
  line-height: 160%;
}
.trip_table_wrapper {
  overflow: auto;
  & div {
    float: left;
  }
}
.passenger {
  .td {
    height: 4em;
  }
  input, .td input{padding-top: 3px;}
}
div.gmnoprint{
  top:21px !important;
  div.gm-style-mtc{top:-18px}
}

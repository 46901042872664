@import "var";

ion-header[curpage='rm'] {
  background-color: transparent;

  & ion-toolbar {
    --background: none;
    background-color: $dark_blue;
    padding-top: 32px;
    height: 74px; /* 32px + 42px */
  }

}
/* Android */
html.plt-android ion-toolbar{
  padding-top: 9px;
  height: auto;
}

#rm {


  & ion-content {
    --background: "none";
  }

  & ion-toolbar, & ion-toolbar > ion-buttons {
    color: black;
  }

  ion-toolbar > ion-buttons {
    color: black;
  }

  /*ion-title {color: white; margin-top: -30px;}
  .header-md::after{background: none}*/
}

#rm_cont {
  --offset-top: 50px;
}

#calendar_wrapper {
  --padding-top: 5px;
  --padding-right: 5px;
  --padding-bottom: 5px;
  --padding-left: 5px;
}
#calendar{
  //padding: 0.3rem 0.3rem 0 0.3rem;
}

img.menu_icon {
  width: 38px;
}

div.tabs-inner {
  //background-color: #ffffff;
}

#rm_segment {
  position: fixed;
  padding-top: 4px;
  --offset-top: 0;
  height: 50px;
  z-index: 99;
}

#below_segment {
  /*position: fixed;
  top: 115px;*/
  margin-top: 50px;
  left: 0;
  width: 100%;
  height: calc(100vh - 166px - var(--statusbar_mb_padding));/*ion-header + ion-tab-bar + top buttons*/
  background-color: $grey_bg;
}
#map {
  min-height: 300px;
  min-height: 35vh;
  height: calc(100vh - 130px);

  &.has_il {
    height: 50%;
  }
}

#il_wrap {
}
#ask_rm_modal{
  --border-radius: 5px;
}
#ask_rm_modal div.modal-wrapper{
  height: auto;
  padding-right: 5%;
  padding-left: 5%;
  --padding-right: 5%;
  --padding-left: 5%;
  --height: auto;
  border-radius: 5px;
  --border-radius: 5px;

  & > ion-content {
    padding: 10px
  }

  &.full_width {
    width: 100%;
    margin: 0;
  }
}
#inline_list {
  overflow-y: scroll;
  padding: 0;
  height: 50%;
  & > ion-item {
    --inner-padding-bottom: 0;
    margin: 6px 6px 6px 6px;
    padding-right: 6px;
    &>ion-label{
      margin-top: 6px;
      padding-left: 6px;
      margin-right: 0;
    }
    & > .drider_list_idx{
      text-align: center;
      padding-left: 2px;
      padding-right: 2px;
      & > span{
        border-style: solid;
        border-radius: 13px;
        //border-color: var(--ion-color-primary);
        border-width: 1px;
        font-family: 'Roboto Mono', sans-serif;
        line-height: 25px;
        width: 25px;
        display: inline-block;
        color: white;
        background-color: var(--ion-color-primary);
        font-weight: bold;
      }
    }
  }
  ion-accordion-group {
    ion-item{--inner-padding-end: 0}
    ion-item[slot="header"]{
    }
    --padding-start: 3px;
    background: white;
    --ion-color-base: white !important;
  }
}

#map_list {
  height: calc(100vh - 150px)
}

div.info_window {
  line-height: 1.1rem;
  overflow-x: hidden;
  > br{
    display: block;
    content: " ";
    height: 0.4rem;
  }
  ion-button.two_lines[size^="tiny"] {
    font-size: 90%;
    width: 21%; /*info window does not fit width very well*/
  }

}
div.gm-style-iw.gm-style-iw-c button{
  top: -3px;
}
div.sc-ion-action-sheet-md{
  padding-bottom: 33px;
}
.big_anchor a{
  line-height: 24px;
}

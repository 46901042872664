@import 'var';

ion-header[curpage="dash"] {
  background-image: url("../../public/assets/img/hero01.png");
  background-size: cover;
  background-position-y: 32px;
  background-repeat: no-repeat;
  height: 187px;
  z-index: 3;
  color: black;
  & ion-title{
    //color: black;//11/8 following ForgetPw update evs-azure-sqlmi2.11ffb021c1f7.database.windows.net
    color: white;
  }

  & ion-toolbar {
    background-color: $dark_blue;
  }
  & ion-menu-button, ion-buttons{
    //color: black;//11/8 following ForgetPw update
    color: white;
    & span{
      color: white;
    }
  }
}

#dash_bg{
  position: fixed;
  top:0;
  z-index: 3;
  background-size: cover;
  background-repeat: no-repeat;
}

#dash {
  & ion-content {
    margin-top: 80px;
    --background: "none";
  }
}

#dash_cont {
  --offset-top: 30px;
  --padding-top: 42px;
  z-index: 2;
  background-color: $grey_bg;
  --background: $grey_bg;
  max-height: calc(100vh - 350px - var(--mb_statusbar_offset));
}

ion-card.infobox {
  z-index: 3;
  width: 95%;
  padding: 10px 5px;
  //margin: 201px auto -36px;
  margin: -36px auto;
  height: 230px;
}

#menus {
  background-color: #efefef;
  padding: 114px 10px 0 10px;
  margin-top: -114px;
}

#menus > ion-item {
  --ion-item-background: transparent;
}

img.menu_icon {
  width: 38px;
  background-color: #efefef;
}
